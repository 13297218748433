@use '@angular/material' as mat;


$medium-spinner: $brand-success !default;
$inner-spinner: $brand-warning !default;
$outer-spinner: $brand-danger !default;

.settings-table {
  height: 100%;
}

.filter-row {
  @media screen and (max-width: $res435) {
    width: 100%;
    margin: 0;
  }
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 0px;

  &>div {
    margin-left: 15px !important;
    padding-left: 0 !important;
    margin-right: 0px !important;
    padding-right: 0 !important;
  }

  :first-child {
  }

  :last-child {
    margin-right: 0px !important;
  }

  &.filter-row-with-action {
    &>div:last-child {
      margin-right: 20px !important;
    }
  }
}

.search-form {
  min-width: 20%;
}

.col-hidden {
  width: 0px;
  min-width: 0px;
  max-width: 0px;
}

.table-detailed-list {
  overflow: hidden;
  white-space: nowrap;
  background-color: transparent;
}

.table-list {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: auto;
  white-space: nowrap;
  height: 25vh;
  min-height: 100px;
  background-color: mat.get-color-from-palette($app-background, dialog);
}

.table-dialog-list {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: auto;
  background-color: transparent;
  white-space: nowrap;
}

.table-no-record-found {
  padding: 10px;
  white-space: normal;
  font-size: 1rem;
}

.form-field-popup {
  cursor: pointer;
}

.mat-column-select {
  overflow: initial;
}

tr.mat-header-row {
  height: 40px !important;
}

.col-select {
  border-bottom-width: 0;
}

.mat-toolbar {
  @media screen and (max-width: $res375) {
    overflow: auto;
  }
  background: mat.get-color-from-palette($app-background, dialog);
  padding: 0 10px;
}

.mat-toolbar-row {
  align-items: center;
  padding: 5px 0;
  height: initial;
}

.details-row {
  height: 0 !important;
}

.details-column-header i {
  padding-top: 5px;
}

.details-column-row mat-icon {
  cursor: pointer;
}

.element-details {
  overflow: hidden;
  display: flex;
}

tr.element-row:not(.expanded-row):hover {
  background: #f5f5f5;
}

tr.element-row:not(.expanded-row):active {
  background: #efefef;
}

.element-row td {
  border-bottom-width: 0;
}

.action-icon-large i {
  font-size: $font-size-xl;
}

.action-cell {
  padding: 0 5px !important;
}

.spinner-autorefresh {
  position: relative;
  top: 0;
  left: 30%;
  width: 100%;
  height: 100%;
  z-index: 1003;
  background: inherit;

  & > div {
    display: block;
    position: relative;
    width: 30px;
    height: 30px;
    margin: 0 0 0 0;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: $outer-spinner;
    transform: translate3d(0, 0, 0);
    animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    &:before {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      border-radius: 50%;
      border: 2px solid transparent;
      border-top-color: $medium-spinner;
      -webkit-animation: spin 1.8s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 1.8s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }

    &:after {
      content: "";
      position: absolute;
      top: 6px;
      left: 6px;
      right: 6px;
      bottom: 6px;
      border-radius: 50%;
      border: 2px solid transparent;
      border-top-color: $inner-spinner;
      animation: spin 1.2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }
  }
}

.refresh-icon {
  min-height: 24px;
}

.right-actions > .mat-slide-toggle {
  margin-right: 10px;
}

.mat-slide-toggle.mat-checked {
  .mat-slide-toggle-thumb {
    background-color: $brand-primary;
  }
}

.auto-refresh-slider {
  margin-right: 5px !important;
  .mat-slide-toggle-label {
    .mat-slide-toggle-bar  {
      margin-left: 5px !important;
      margin-right: 5px !important;
    }
    .mat-slide-toggle-content  {
      margin-right: 5px !important;
    }
  }
}

.right-actions {
  > button:last-child {
    margin-right: 0;
  }
  > button {
    margin-right: 5px;
  }
}

.left-actions {
  > button:first-child {
    margin-left: 0;
  }
  > button {
    margin-right: 5px;
  }
}

.spinner-autorefresh2 {
  position:relative;
  top:0;
  left:2px;
  width:100%;
  height:100%;
  z-index:1003;
  background: transparent;
  overflow:display
}

.spinner-autorefresh2 div:first-child{
  display:block;
  position:relative;
  left:0px;
  top:0px;
  width:24px;
  height:24px;
  margin:0px 0 0 0px;
  border-radius:50%;
  box-shadow:0 2px 2px 0;// rgba(255,56,106,1);
  transform:translate3d(0,0,0);
  animation:spin 1.5s linear infinite
}

.spinner-autorefresh2 div:first-child:after,.spinner div:first-child:before{
  content:'';
  position:absolute;
  border-radius:50%
}

.spinner-autorefresh2 div:first-child:after{
  top:4px;
  left:4px;
  right:4px;
  bottom:4px;
  box-shadow:0 2px 2px 0;// rgba(255, 228, 32,1); //0 3px 3px 0 rgba(61, 175, 255,1);
  animation:spin 1s linear infinite
}

.spinner-refresh-button {
  position: relative;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 1003;
  background: inherit;

  & > div {
    display: block;
    position: relative;
    width: 24px;
    height: 22px;
    margin: 0 0 0 0;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: $outer-spinner;
    transform: translate3d(0, 0, 0);
    animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    &:before {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      border-radius: 80%;
      border: 2px solid transparent;
      border-top-color: $inner-spinner;
      -webkit-animation: spin 5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }
  }
}

.refresh-box {
  min-width: 90px
}

@mixin col-fixsize($width) {
  width: $width;
  min-width: $width;
  max-width: $width;
}

@mixin col-size($width) {
  width: $width;
  min-width: $width;
}

@for $i from 1 through 100 {
  .col-#{$i}fem {
    @include col-fixsize(1em*$i)
  }
}

@for $i from 1 through 100 {
  .col-#{$i}em {
    @include col-size(1em*$i)
  }
}

@for $i from 1 through 100 {
  .col-#{$i}p {
    @include col-size(1%*$i)
  }
}

.table {
  background: rgba(255,255,255,0.3);
  margin-bottom: 0;

  > thead {
    top: 0;
  }

  > thead > tr > th {
    border-bottom-width: 1px;
    position: sticky;
    top: 0px;
    background-color: darken($brand-background, 5%);
    z-index: 100;
  }

  > tfoot > tr > td {
    position: sticky;
    bottom: -1px;
    background: $brand-background;
    z-index: 100;
  }

  > tbody > tr:first-child > th {
    border-top: none;
    cursor: default;
  }

  > :not(:first-child) {
    border-top: 0;
  }

  > thead tr th:first-child {
    padding-left: 15px;
  }

  > thead tr th:last-child {
    padding-right: 15px;
  }

  > tbody tr td:first-child {
    padding-left: 15px !important;
  }

  > tbody tr td:first-child {
    padding-right: 15px !important;
  }

  tr:nth-child(even) {
    background-color: darken($brand-background, 2%);
  }

  .tab-pane & {
    tbody > tr > td:first-child {
      width: 36px;
    }
  }

  .radio,
  .checkbox {
    margin-top: -1px;
    margin-bottom: 0;
    padding: 0;
    width: 15px;

    .icons {
      position: relative;
    }
  }

  .sortable {
    cursor: pointer !important;
  }

  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    font-size: $font-size-sm;
    vertical-align: middle;
  }

  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th {
    font-size: $font-size-sm;
    vertical-align: middle;
  }

  .table-cell-angular-component {
    vertical-align: middle !important;
  }

  .table-cell-angular-big-component {
    padding: 5px 8px 4px 8px;
    button {
      margin-top: -2px !important;
    }
  }

  .table-cell-angular-input-component {
    margin-bottom: 10px !important;
  }

  .table-cell-angular-buttons-component {
    padding: 0px 8px 0px 8px;
    button {
      margin-top: -2px !important;
    }
  }

  .table-angular-details-component {
    padding: 0;
  }

  .table-mat-error {
    font-size: $font-size-xxs;
  }

  .th-description {
    max-width: 150px;
  }
  .td-price {
    font-size: 26px;
    margin-top: 5px;
    text-align: right;
  }
  .td-total {
    font-size: $font-size-h5;
    padding-top: 14px;
    text-align: right;
  }

  .td-actions .btn {
    margin: 0px;
    padding: 5px;
  }

  .row-validation {
    height: 0 !important;
    td {
      text-align: left !important;
      padding-top: 0 !important;
      font-size: 75% !important;
      border-bottom: none !important;
      border-top: none !important;
    }
  }

  .table-field-invalid {
    input {
      border-style: solid;
      border-color: $brand-danger;
    }
  }

  > tbody > tr {
    position: relative;
    height: 38px !important;
  }

  .flag {
    img {
      max-width: 18px;
      margin-top: -2px;
    }
  }
}

.more-records {
  position: inherit;
  min-height: 30px;
  padding-top: 5px;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  background-color: $brand-background;
}

.bottom-table {
  position: sticky;
  bottom: 0;
  left:0;
  width:100%;
  padding: 10px;
  background: $brand-background;
  z-index: 100;

  .left-bottom-table {
  }
  .right-bottom-table {
    text-align: right;
  }
}

.table-no-border {
  td {
    border-bottom: none !important;
    border-top: none !important;
  }
}

.table-warning {
  border-color: $brand-danger !important;
}

.action-container {
  margin: 5px;
}
