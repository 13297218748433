
.nav-tabs {
  border: 0;
  border-radius: $border-radius-base;
  padding: 0 15px;

  .nav-item {
    .nav-link {
      color: $brand-text;
      border: 0;
      margin: 0;
      border-radius: $border-radius-base;
      line-height: $mdb-btn-font-size-base * 2;
      font-size: $mdb-btn-font-size-base;
      padding: 10px 15px;
      background-color: transparent;
      transition: 0.3s background-color 0s;

      &:hover {
        border: 0;
      }
    }
    .nav-link,
    .nav-link:hover,
    .nav-link:focus {
      border: 0 !important;
      color: $brand-background !important;
      font-weight: $font-weight-bold;
    }
    &.disabled .nav-link,
    &.disabled .nav-link:hover {
      color: rgba(255, 255, 255, 0.5);
    }

    .material-icons {
      margin: -1px 5px 0 0;
    }

    .nav-link.active {
      background-color: $brand-background;
      color: $brand-primary !important;
      transition: 0.3s background-color 0.2s;
    }
  }
}

.material-icons {
  vertical-align: middle;
}

.nav-tabs {
  .nav-link {
    border-bottom: $bmd-nav-tabs-border-size solid transparent;
  }

  // colors
  @include bmd-tabs-color($bmd-nav-tabs-color, $bmd-nav-tabs-active-color, $bmd-nav-tabs-active-border-color, $bmd-nav-tabs-disabled-link-color, $bmd-nav-tabs-disabled-link-color-hover);

  &.header-primary {
    @include bmd-tabs-color($bmd-nav-tabs-primary-color, $bmd-nav-tabs-primary-active-color, $bmd-nav-tabs-primary-active-border-color, $bmd-nav-tabs-primary-disabled-link-color, $bmd-nav-tabs-primary-disabled-link-color-hover);
  }

  &.bg-inverse {
    @include bmd-tabs-color($bmd-nav-tabs-inverse-color, $bmd-nav-tabs-inverse-active-color, $bmd-nav-tabs-inverse-active-border-color, $bmd-nav-tabs-inverse-disabled-link-color, $bmd-nav-tabs-inverse-disabled-link-color-hover);
  }
}

.card-nav-tabs {
  margin-top: 45px;

  .card-header {
    margin-top: -30px !important;
  }
}

.tabs-actions {
  position: absolute;
  top: -15px;
  right: 15px;
  z-index: 999999999;
  height: 58px;
  padding-top: 7px;
  padding-right: 8px;
  background: $brand-primary;
  color: $brand-background;
}

.tabs-actions-embedded {
  position: absolute;
  top: 0px;
  right: 15px;
  z-index: 999999999;
  height: 59px;
  padding-top: 7px;
  padding-right: 8px;
  background: $brand-primary;
  color: $brand-background;
}

.mat-tab-body-content {
  height: 100%;
  .card-body {
    margin-top: initial !important;
  }
}

.mat-tab-dialog-body-content {
  align-items: center;
  justify-content: center;
  .card-body {
    margin-top: initial !important;
  }
  .tab-content {
    height: 100%;
  }
}

.tab-content {
  width: 100%;
}

.mat-tab-header-pagination {
  background: $brand-primary;
  box-shadow: initial;
  min-width: 25px !important;
}

.mat-tab-header-pagination-chevron {
  border-color: $brand-background !important;
}
.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: $brand-background !important;
}

.mat-tab-header-pagination {
  box-shadow: initial !important;
}

.mat-dialog-container {
  .mat-tab-header-pagination {
    padding-top: 4px;
  }
}

.mat-tab-labels {
  padding: 7px;
  color: $brand-background !important;
}

.mat-tabs-with-close-action {
  .mat-tab-header {
    margin-right: 55px !important;
  }
}

.transparent-dialog-container {
  max-width: initial !important;

  .mat-dialog-container {
    background: none;
    box-shadow: none;
    overflow: hidden;
  }

  .mat-tab-group {
    margin-top: -20px;
  }

  .mat-tab-header {
    margin-left: 15px;
    margin-right: 15px;
    background: $brand-primary;
  }

  .mat-tabs-with-actions {
    .mat-tab-header {
      margin-right: 90px !important;
    }
  }

  .mat-tabs-with-close-action {
    .mat-tab-header {
      margin-right: 55px !important;
    }
  }

  .mat-tab-labels {
    padding: 11px 7px 7px 7px;
  }
}

.mat-tab-info .mat-tab-labels {
  background: $brand-primary;
}

.mat-tab-danger .mat-tab-labels {
  background: $brand-danger;
}

.mat-ink-bar {
  visibility: hidden !important;
}

.mat-tab-header {
  border-bottom: none;
}

.mat-tab-label {
  opacity: 1 !important;
  color: $brand-background !important;
  border: 0;
  margin: 0;
  height: 45px !important;
  min-width: 3em !important;
  border-radius: $border-radius-base;
  line-height: $mdb-btn-font-size-base * 2;
  font-size: $mdb-btn-font-size-base;
  padding: 0 0.8em !important;
  margin-right: 5px;
  background-color: transparent;
  transition: 0.3s background-color 0s;
  text-transform: uppercase;
  &:focus {
    border: 0 !important;
    font-weight: $font-weight-bold;
  }
  &:hover {
    background-color: rgba($brand-background, .1);
  }
  .btn-link {
    color: white !important;
  }
}

.mat-tab-disabled {
  margin-left: auto !important;
  padding: 0 !important;
  justify-content: end !important;

  &:hover {
    background: transparent;
  }
}

.mat-tab-label-active {
  color: $brand-primary !important;
  background-color: $brand-background !important;
  border: 0;
  margin: 0;
  border-radius: $border-radius-base;
  line-height: $mdb-btn-font-size-base * 2;
  font-size: $mdb-btn-font-size-base;
  padding: 10px 15px;
  transition: 0.3s background-color 0.2s;
  &:hover {
    background-color: $brand-background;
  }
}
.mat-tab-body.mat-tab-body-active {
  overflow: hidden !important;
}

.mat-tab-body-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  flex-grow: 1;
  height: 90%;
  .mat-tab-body {
    height: 100%;
  }
}
